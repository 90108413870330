/**
  * Accordion toggling
 */

const accordionToggle = () => {
  const accordionTitle = document.querySelectorAll('.js-accordion-title')
  const accordionSubtitle = document.querySelectorAll('.js-accordion-subtitle')

  for(let item of accordionTitle) {
    item.addEventListener('click', () => {
      item.parentElement.classList.toggle('faq__block--active')
      let content = item.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null
      } else {
        content.style.maxHeight = content.scrollHeight + 'px'
      }
    })
  }

  for(let item of accordionSubtitle) {
    item.addEventListener('click', () => {
      item.parentElement.classList.toggle('faq__item--active')
      let content = item.nextElementSibling
      if (content.style.maxHeight) {
        content.style.maxHeight = null
        let mainContent = parseInt(item.parentElement.parentElement.style.maxHeight)
        item.parentElement.parentElement.style.maxHeight = (mainContent - content.scrollHeight) + 'px'
      } else {
        content.style.maxHeight = content.scrollHeight + 'px'
        let mainContent = parseInt(item.parentElement.parentElement.style.maxHeight);
        item.parentElement.parentElement.style.maxHeight = (mainContent + content.scrollHeight) + 'px'
      }
    })
  }
}

export default accordionToggle
