import accordionToggle from './components/accordionToggle'
import backToTop from './components/backToTop'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    accordionToggle()
    backToTop()
  },
  false
)

window.addEventListener('load', function () {
  AOS.init({ once: true })
})
