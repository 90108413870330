/**
 * Shows back to top on scroll 
 *  and add back to top functionality
 */

const backToTop = () => {
  const backTop = document.querySelector('.footer__top')

  backTop.addEventListener('click', () => {
    document.body.scrollIntoView({ behavior: 'smooth' })
  })

  if(window.pageYOffset >= 500) {
    backTop.classList.add('footer__top--active')
  } else {
    backTop.classList.remove('footer__top--active')
  }

  window.addEventListener('scroll', () => {
    if(window.pageYOffset >= 500) {
      backTop.classList.add('footer__top--active')
    } else {
      backTop.classList.remove('footer__top--active')
    }
  })
}

export default backToTop
